import logo from './logo.svg';
import './App.css';
import { FaHome } from 'react-icons/fa';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <div className="App-footer">
          Version: {process.env.REACT_APP_VERSION}
        </div>
        <p>
          Welcome to your fourthy twos - Don't panic, please.
        </p>
      </header>
    </div>
  );
}

export default App;
